document.addEventListener("turbo:load", function() {
  let navToggle = document.querySelector(".l-header-nav-toggle-btn");
  let navWrapper = document.querySelector(".l-header-nav-lists");
  const wrapper = document.querySelector(".l-content-wrapper");

  navToggle.addEventListener("click", function () {
    if (navWrapper.classList.contains("active")) {
      this.setAttribute("aria-expanded", "false");
      this.setAttribute("aria-label", "menu");
      navWrapper.classList.remove("active");
    } else {
      navWrapper.classList.add("active");
      this.setAttribute("aria-label", "close menu");
      this.setAttribute("aria-expanded", "true");
      wrapper.addEventListener('click', function(){
        navWrapper.classList.remove("active");
      })
    }
  });
});
