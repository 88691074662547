import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    close() {
        this.element.remove()

        if(document.getElementById("modal").getAttribute("src") != null){
            document.getElementById("modal").removeAttribute("src");
        }
    }

    escClose(event) {
        if (event.key === 'Escape') this.close()
    }
}