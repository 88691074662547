import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["source", "reason", "dayStatus", "staffCheck", "reasonInput"]

    initialize() {
        this.change();
    }

    change() {
        if(/business|ladies/g.test(this.dayStatusTarget.value)){
            this.reasonInputTarget.value = null
            this.reasonTarget.hidden = true
            this.staffCheckTargets.filter( sc => sc.checked = true)
            this.sourceTarget.hidden = false
        }else{
            this.reasonTarget.hidden = false
            this.staffCheckTargets.filter( sc => sc.checked = false)
            this.sourceTarget.hidden = true
        }
    }

}
