import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "output"]

    preview() {
        var input = this.inputTarget
        var output = this.outputTarget
        var size_in_megabytes = this.inputTarget.files[0].size / 1024 / 1024;
        var reader = new FileReader();

        if (size_in_megabytes > 5) {
            alert("サイズが大きすぎます。");
            input.value = null;
            output.value = null;
            return 0;
        }

        if (input.files && input.files[0]) {
            reader.onload = function () {
                output.width = 100
                output.src = reader.result
            }

            reader.readAsDataURL(input.files[0]);
        }

    }
}
