import ScrollHint from "scroll-hint";

document.addEventListener("turbo:load", function() {
  new ScrollHint(".js-scrollable", {
    scrollHintIconAppendClass: "scroll-hint-icon-black", // white-icon will appear
    applyToParents: true, enableOverflowScrolling: true, suggestiveShadow: true, i18n: {
      scrollable: "横スクロール"
    }
  });
});
