import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["source", "selectValue"]

    initialize(){
        this.staffStatus();
    }

    staffStatus(){
        if(this.selectValueTarget.value === "move"){
            this.sourceTarget.hidden = false
        }else{
            // this.staffCheckTargets.filter( sc => sc.checked = true)
            this.sourceTarget.hidden = true
        }
    }
}
