import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

export default class extends Controller {
    static targets = ["trigger", "content"]

    initialize() {
        this.initPopup();

        this.contentTarget.style.display = "none";
    }

    initPopup() {
        tippy(this.triggerTarget, {
            content: this.contentTarget.innerHTML,
            allowHTML: true,
            duration: "[350, 300]",
            theme: "lazer",
            interactive: true,
        });
    }
}