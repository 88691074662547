document.addEventListener("turbo:load", function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 100) {
      $(".c-back-to-top").fadeIn("slow");
    } else {
      $(".c-back-to-top").fadeOut("slow");
    }
  });

  $(".c-back-to-top").click(function() {
    $("html, body").animate({
      scrollTop: 0
    }, 1500, "easeInOutExpo");
    return false;
  });
});
