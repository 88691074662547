document.addEventListener("turbo:load", function() {
  let preloader = $("#preloader");

  if (preloader.length) {
    preloader.delay(5).fadeOut("slow", function() {
      $(this).remove();
    });
  }
});

