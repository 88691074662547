import "./jquery"
import "./jquery_nested_form"
import "./header_nav"
import "./aos"
import "./back_to_btn"
import "./calendar"
import "./gallery"
import "./preloader"
import Accordion from "./accordion";

window.addEventListener('turbo:load', () => {
  Accordion.moreListsAccordion({
    defaultShowListSize: 5,
    moreListsAccordionClass: '.js-moreShopImages',
    moreListClass: '.js-moreShopImage',
    onlyUseSmartPhone: false
  });
});