import Rails from '@rails/ujs'
import Sortable from "sortablejs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            handle: "i.handle",
            axis: 'y',
            animation: 300,
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let id = event.item.dataset.id
        let shop_id = event.item.dataset.shop_id
        let data = new FormData()
        let url = this.data.get("url").replace(":id", id)

        data.append("position", event.newIndex + 1);
        data.append("shop_id", shop_id);

        Rails.ajax({
            url: url,
            type: "PATCH",
            data: data
        })
    }
}