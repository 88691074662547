// LandingPage3内で使用するアコーディオンのクラス
export default class Accordion {
  static toggleAccordion(openElementClass) {
    $(openElementClass).on('click', function () {
      $(this).next().slideToggle();
      $(this).toggleClass("selected");
    });
  }
  // 「もっと見る」ボタンの実装
  // 必要なapplication.jsに下記のように設定する
  //  Accordion.moreListsAccordion({
  //     defaultShowListSize: 2,
  //     moreListsAccordionClass: '.js-moreItems',
  //     moreListClass: '.js-moreItem',
  //     onlyUseSmartPhone: true
  //   });
  //
  // View側では下記の書き方になる
  //  .js-moreItems
  //    %ul
  //      %li.js-moreItem リスト1
  //    = button_tag "もっと見る ▽", type: :button, class: 'js-moreOpenButton'
  //    = button_tag "閉じる △", type: :button, class: 'js-moreCloseButton'
  static moreListsAccordion({
                              defaultShowListSize = null,
                              smartPhoneShowListSize = defaultShowListSize,
                              moreListsAccordionClass = null,
                              moreListClass = null,
                              onlyUseSmartPhone = false
                            }) {
    const $moreListsAccordion = $(moreListsAccordionClass);
    const $moreList = $(moreListClass);

    if (!defaultShowListSize || !$moreListsAccordion || !$moreList) {
      return;
    }
    // スマートフォンでのみ「もっと見る」機能を使用する場合はPC画面ではreturnを返し「もっと見る」機能を実行しない。
    if (onlyUseSmartPhone && !(window.matchMedia && window.matchMedia('(max-width: 768px)').matches)) {
      $moreListsAccordion.find(".js-moreOpenButton").hide();
      $moreListsAccordion.find(".js-moreCloseButton").hide();
      return;
    }
    // LISTの合計数
    const moreListCount = $moreList.length;
    // 表示させたいLISTの数
    let showListSize = defaultShowListSize;
    // スマホ画面で表示させたいLISTの数
    if (window.matchMedia && window.matchMedia('(max-width: 768px)').matches) {
      showListSize = smartPhoneShowListSize;
    }
    const closeListSize = showListSize - 1;

    $moreListsAccordion.find(".js-moreOpenButton").show();
    $moreListsAccordion.find(".js-moreCloseButton").hide();
    $moreListsAccordion.find(`${moreListClass}:not(:lt(${showListSize}))`).hide();
    // 「もっと見る」ボタンをクリック
    $moreListsAccordion.find(".js-moreOpenButton").click(() => {
      // 指定した数値分表示する
      if (window.matchMedia && window.matchMedia('(max-width: 768px)').matches) {
        showListSize += smartPhoneShowListSize;
      }else{
        showListSize += defaultShowListSize;
      }
      $moreListsAccordion.find(`${moreListClass}:lt(${showListSize})`).slideDown();

      // 全て表示した場合
      if (moreListCount <= showListSize) {
        $moreListsAccordion.find(".js-moreOpenButton").hide();
        $moreListsAccordion.find(".js-moreCloseButton").show();
        // 「閉じる」ボタンをクリック
        $moreListsAccordion.find(".js-moreCloseButton").click(() => {
          // 表示させたいLISTの数をリセットする
          if (window.matchMedia && window.matchMedia('(max-width: 768px)').matches) {
            showListSize = smartPhoneShowListSize;
          }else{
            showListSize = defaultShowListSize;
          }
          $moreListsAccordion.find(`${moreListClass}:gt(${closeListSize})`).slideUp();
          $moreListsAccordion.find(".js-moreCloseButton").hide();
          $moreListsAccordion.find(".js-moreOpenButton").show();
        });
      }
    });
  }
}