import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["table"]

    initialize() {
        this.table.hidden = true;
    }

    openTable() {
        this.table.hidden = this.table.hidden === false;
    }

    get table() {
        return this.tableTarget
    }
}
