import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"
import { supportsWebp } from "./supports/webp";
ActiveStorage.start();
import "./controllers"
import "./supports"

/**
 * webp 対応していれば target に is-webp、対応していなければ is-no-webp クラスを追加する
 *
 * @param {string} target (default: body)
 */
const addWebpDetectionClass = (target = 'body') => {
  if (supportsWebp()) {
    $(target).addClass('is-webp');
  } else {
    $(target).addClass('is-no-webp');
  }
};

addWebpDetectionClass(); // 実行
